export const environment = {
  production: true,
  whitelistedDomains: ['tecweb-orderpro-masterdataservice-qa.azurewebsites.net'],
  tecWebSiteUrl: 'https://tecweb-orderpro.qa.tecalliance.cloud/newapp',
  masterDataServiceUrl: 'https://tecweb-orderpro-masterdataservice-qa.azurewebsites.net/Api',
  enableOrderManagerBeta: true,
  signalRUrl: 'https://tecnotification-qa.azurewebsites.net',
  enableReturnsBeta: true,
  statusPageUrl: 'https://status.tecalliance.net',
  defaultRoute: '/home/dashboard',
  reportAnIssueUrl: 'https://ordersupportweb-qa-frontend.azurewebsites.net',
  orderManagerWikiUrl: 'https://help-ordermanagerportal.tecalliance.net',
  knowledgeDatabaseApiUrl: 'https://h3rlhecxz7.execute-api.eu-central-1.amazonaws.com/qa',
  oktaOptions: {
    clientId: '0oa1rn356wpb9hpSg0x7',
    issuerUri: 'https://login.qa.tecalliance.cloud/oauth2/default',
    redirectUri: `${window.location.origin}/callback`,
    postLogoutRedirectUri: 'https://tecweb-orderpro.qa.tecalliance.cloud/newapp/auth/welcome'
  },
  moduleSubdomains: {
    returns: 'https://returns.',
    tecweb: 'https://tecweb-orderpro.',
    connect: 'https://connectweb.',
    vcTool: 'https://validationcertification.',
    dashboard: 'https://dashboard.',
    mfe: 'https://teccom-portal.'
  },
  currentModule: 'https://validationcertification.qa.tecalliance.cloud',
  showTxml5: true
};
